import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ThemeProviderHandler from './context/themes/ThemeProvider.jsx';
import { Box, ThemeProvider, useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useGlobal } from './context/global/GlobalProvider.jsx';
import { useAuth } from './context/auth/AuthProvider.jsx';

import ScrollToTop from './components/scrollToTop/index.jsx';
import Nav from './components/nav/index.jsx';
import Loader from './components/loader/index.jsx';
import SearchAppBar from './components/appBar/index.jsx';
import LoginPage from './pages/login/index.jsx';
import ProfilePage from './pages/profile/index.jsx';
import SignUpPage from './pages/signUp/index.jsx';
import CreateJokePage from './pages/createJoke/index.jsx';
import UserJokesPage from './pages/userJokes/index.jsx';
import AllJokesPage from './pages/allJokes/index.jsx';

const App = () => {
  const theme = useTheme();
  const { authUser } = useAuth();
  const { loading } = useGlobal();

  return (
    <ThemeProvider theme={theme}>
      <ThemeProviderHandler>
        <ScrollToTop />
        <ToastContainer />
        {!authUser ? (
          <Box
            sx={{
              minHeight: '100vh',
              minWidth: '100vw',
              padding: '0px 20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Routes>
              <Route path='/' element={<LoginPage />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/signup' element={<SignUpPage />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </Box>
        ) : (
          <Box component='div' sx={{ padding: '0px', display: 'flex' }}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Nav />
                <Box
                  id='main'
                  component='main'
                  sx={{
                    margin: '0px 0px 0px 270px',
                    width: 'calc(100% - 270px)',
                    padding: '0px 20px',
                    display: 'block',
                    height: '100%',
                    transition: 'all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
                    '@media (max-width: 1200px)': {
                      margin: '0 auto',
                      width: '95%',
                    },
                  }}
                >
                  <SearchAppBar />
                  <Routes>
                    <Route path='/' element={<AllJokesPage />} />
                    <Route path='/home' element={<AllJokesPage />} />
                    <Route path='/jokes' element={<AllJokesPage />} />
                    <Route path='/jokes/create' element={<CreateJokePage />} />
                    <Route path='/account' element={<ProfilePage />} />
                    <Route path='/account/jokes' element={<UserJokesPage />} />
                  </Routes>
                </Box>
              </>
            )}
          </Box>
        )}
      </ThemeProviderHandler>
    </ThemeProvider>
  );
};

export default App;
