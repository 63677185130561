import * as React from 'react';
import GlobalContext from './GlobalContext';

// Create hook useGlobal
export const useGlobal = () => {
  const context = React.useContext(GlobalContext);
  if (!context) {
    throw new Error(
      'useGlobal() only can be used with <GlobalContext.provider>',
    );
  }
  return context;
};

// Create context provider
export function GlobalContextProvider(props) {
  const [loading, setLoading] = React.useState(false);

  const value = React.useMemo(() => ({ loading, setLoading }), [loading]);

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
}
