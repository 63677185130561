import * as React from 'react';
import { useGlobal } from '../../context/global/GlobalProvider';
import { useAuth } from '../../context/auth/AuthProvider';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

const SignUp = () => {
  const { setLoading } = useGlobal();
  const { signUp } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await signUp(data.email, data.password, data.pseudo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant='outlined'
        label='Pseudo*'
        type='text'
        fullWidth
        autoComplete='pseudo'
        autoFocus
        {...register('pseudo', {
          required: 'Champ obligatoire',
          pattern: {
            value: /^([a-zA-Z0-9]){3,20}$/,
            message: 'Pseudo invalide',
          },
        })}
        error={!!errors?.pseudo}
        helperText={errors?.pseudo ? errors.pseudo.message : null}
        sx={{
          marginTop: 2,
          color: (theme) => theme.palette.secondary.main,
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
          },
          '& label': {
            color: (theme) => theme.palette.text.primary,
          },
          '& label.Mui-focused': {
            color: (theme) => theme.palette.text.primary,
          },
        }}
      />
      <TextField
        variant='outlined'
        label='Email*'
        fullWidth
        autoComplete='email'
        autoFocus
        {...register('email', {
          required: 'Champ obligatoire',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Adresse email invalide',
          },
        })}
        error={!!errors?.email}
        helperText={errors?.email ? errors.email.message : null}
        sx={{
          marginTop: 2,
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
          },
          '& label': {
            color: (theme) => theme.palette.text.primary,
          },
          '& label.Mui-focused': {
            color: (theme) => theme.palette.text.primary,
          },
        }}
      />
      <TextField
        variant='outlined'
        label='Mot de passe*'
        type='password'
        fullWidth
        autoComplete='password'
        autoFocus
        {...register('password', {
          required: 'Champ obligatoire',
          pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            message: 'Mot de passe invalide',
          },
        })}
        error={!!errors?.password}
        helperText={errors?.password ? errors.password.message : null}
        sx={{
          marginTop: 2,
          color: (theme) => theme.palette.secondary.main,
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
          },
          '& label': {
            color: (theme) => theme.palette.text.primary,
          },
          '& label.Mui-focused': {
            color: (theme) => theme.palette.text.primary,
          },
        }}
      />
      <Button
        sx={{
          marginBottom: 1.5,
          marginTop: 1.5,
        }}
        type='submit'
        variant='contained'
        fullWidth
      >
        Terminer l'inscription
      </Button>
    </form>
  );
};

export default SignUp;
