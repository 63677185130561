import * as React from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconLogoLight from '../icons/logoLight';
import ButtonSwitchTheme from '../buttonSwitchTheme';
import {
  StyledDrawer,
  StyledNav,
  StyledListItemButton,
  StyledListItemText,
  StyledCloseIcon,
} from './styles';
import { SubjectRounded, CreateRounded, Star, Person2, DataUsage } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import NavOutsideAlerter from './outsideAlerter';

const Nav = () => {
  const profileItems = [
    {
      icon: <Person2 />,
      path: `/account`,
      text: 'Profil',
    },
    {
      icon: <DataUsage />,
      path: `/account/jokes`,
      text: 'Mes blagues',
    },
    {
      icon: <Star />,
      path: `/favorite`,
      text: 'Favoris',
    },
  ];

  const featuresItems = [
    {
      icon: <SubjectRounded />,
      path: `/jokes`,
      text: 'Blagues',
    },
    {
      icon: <CreateRounded />,
      path: `/jokes/create`,
      text: 'Créér',
    },
  ];

  const closeDrawer = () => {
    const asideDrawer = document.getElementById('aside-drawer');
    if (asideDrawer) {
      asideDrawer.style.width = '0%';
      document.getElementById('main').style.margin = '0 auto';
      asideDrawer.style.visibility = 'hidden';
    }
  };

  React.useEffect(() => {
    if (window.innerWidth > 769) {
      document.getElementById('aside-drawer').style.width = '100%';
      document.getElementById('aside-drawer').style.visibility = 'visible';
      document.getElementById('main').style.marginLeft = '270px';
    } else {
      document.getElementById('aside-drawer').style.width = '0%';
      document.getElementById('aside-drawer').style.visibility = 'hidden';
      document.getElementById('main').style.margin = '0 auto';
    }
  });

  const onClickOutside = () => {
    closeDrawer();
  };

  return (
    <NavOutsideAlerter onClickOutside={onClickOutside}>
      <StyledDrawer variant='temporary' anchor='left' id='aside-drawer'>
        <Box sx={{ display: 'inline-flex', justifyContent: 'space-between', margin: '6px 0px' }}>
          <a href='/jokes'>
            <IconLogoLight sx={{ '&:hover': { opacity: 0.5 } }} />
          </a>
          <StyledCloseIcon onClick={closeDrawer} />
        </Box>

        <Divider
          sx={{
            color: (theme) => theme.palette.divider.default,
            width: '90%',
            marginTop: '1.5rem',
          }}
        />

        <StyledNav>
          <List>
            {featuresItems.map((icon, index) => {
              return (
                <ListItem key={icon.text} disablePadding>
                  <StyledListItemButton component={Link} to={icon.path} key={index}>
                    <ListItemIcon sx={{ color: (theme) => theme.bodyColor }}>
                      {icon.icon}
                    </ListItemIcon>
                    <StyledListItemText
                      primary={
                        <Typography variant='h6' style={{ fontWeight: 550 }}>
                          {icon.text}
                        </Typography>
                      }
                    />
                  </StyledListItemButton>
                </ListItem>
              );
            })}
          </List>

          <Divider sx={{ color: (theme) => theme.palette.divider.default, width: '90%' }} />

          <List>
            {profileItems.map((icon, index) => {
              return (
                <ListItem key={icon.text} disablePadding>
                  <StyledListItemButton component={Link} to={icon.path} key={index}>
                    <ListItemIcon sx={{ color: (theme) => theme.bodyColor }}>
                      {icon.icon}
                    </ListItemIcon>
                    <StyledListItemText
                      primary={
                        <Typography variant='h6' style={{ fontWeight: 550 }}>
                          {icon.text}
                        </Typography>
                      }
                    />
                  </StyledListItemButton>
                </ListItem>
              );
            })}
          </List>
        </StyledNav>

        <Divider
          sx={{
            color: (theme) => theme.palette.divider.default,
            width: '90%',
            marginBottom: '10px',
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
          <ButtonSwitchTheme />
        </Box>
        <Box
          component='p'
          sx={{ color: (theme) => theme.ultraLightGray, marginBottom: '10px', marginTop: '10px' }}
        >
          &nbsp;© 2023 JokeHub, Inc.
        </Box>
      </StyledDrawer>
    </NavOutsideAlerter>
  );
};

export default Nav;
