import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { toast } from 'react-toastify';
import { getAllJokes } from './jokes';

export const getDocUserByUid = async (uid) => {
  return new Promise(async (resolve, reject) => {
    const q = query(collection(db, 'users'), where('uid', '==', uid));

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log('user: ', doc.id, ' => ', doc.data());
          resolve(doc);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDocUserByUid = async (uid, data) => {
  getDocUserByUid(uid).then((docUser) => {
    if (docUser) {
      const docUserRef = doc(db, 'users', docUser.id);
      updateDoc(docUserRef, data)
        .then(() => {
          toast.success('User updated ✅', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch(() => {
          toast.error('Problème lors de la mise à jour', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  });
};

export const getJokesByUser = async (userData) => {
  return getAllJokes()
    .then((allJokes) => {
      const result = allJokes.filter((joke) => userData?.[0].jokes.includes(joke.id));
      return result;
    })
    .catch(() => {
      toast.error('Erreur lors du chargement des données', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
};
