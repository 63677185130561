export const generateChuckNorrisJoke = async () => {
    fetch('https://api.chucknorris.io/jokes/random').then(response => {
      if (!response.ok) {
        throw new Error('La requête a échoué');
      }
      return response.json();
    })
    .then(data => {
      const el = document.getElementById('create-joke-question');
      if (el) el.innerHTML = data.value;
    })
    .catch(error => {
      console.error(error);
    });
  };