import * as React from 'react';
import { Button } from '@mui/material';
import { SaveSharp } from '@mui/icons-material';


const ButtonSave = (props) => {

  return (
    <Button
      sx={{
        background: (theme) => theme.palette.background.default,
        margin: '20px 0px',
        fontWeight: 'bold',
      }}
      type='submit'
      variant='contained'
      endIcon={<SaveSharp />}
    >
      {props.value}
    </Button>
  );
};

export default ButtonSave;
