import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import ButtonSwitchTheme from '../../components/buttonSwitchTheme';
import SignUp from '../../components/signUp';

const SignUpPage = () => {
  return (
    <Container
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.backgroundBlock.default,
          borderRadius: '16px',
          boxShadow:
            '0px 2px 8px 2px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 10px 10px 0px rgba(0,0,0,0.12)',
          maxWidth: '806px',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            borderRadius: '12px 12px 0px 0px',
            borderBottom: (theme) => '1px solid ' + theme.palette.divider.default,
            textAlign: 'center',
            fontWeight: 550,
            padding: '6px 0px',
          }}
        >
          JokeHub &gt; Créez votre compte
        </Typography>

        <Container
          sx={{
            padding: '15px 20px',
          }}
        >
          <SignUp />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link
              sx={{
                textDecoration: 'underline',
                color: (theme) => theme.palette.secondary.main,
              }}
              href='/login'
            >
              Revenir à la page de connexion
            </Link>
            <ButtonSwitchTheme sx={{ marginRight: 'auto' }} />
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default SignUpPage;
