import * as React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { StyledArticle, StyledButton } from './styles';
import { FavoriteTwoTone, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useAuth } from '../../context/auth/AuthProvider';
import { useGlobal } from '../../context/global/GlobalProvider';
import { addFavoriteJoke, removeFavoriteJoke } from '../../services/jokes';

const Jokes = ({ currentJokes }) => {
  const [expandedAnswersIndexes, setExpandedIndexes] = React.useState([]);
  const { userData } = useAuth();
  const { setLoading } = useGlobal();

  const toggleAnswerVisibility = (index) => {
    setExpandedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((prevIndex) => prevIndex !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  const newFavoriteJoke = async (joke) => {
    try {
      setLoading(true);
      await addFavoriteJoke(userData, joke).then(() => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const reduceFavoriteJoke = async (joke) => {
    try {
      setLoading(true);
      await removeFavoriteJoke(userData, joke).then(() => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    // Reinitialize expandedAnswersIndexes on currentJokes changed
    setExpandedIndexes([]);
  }, [currentJokes]);

  return (
    <>
      {currentJokes.map((joke, index) => (
        <StyledArticle key={index}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              component='p'
              sx={{
                fontWeight: 550,
                fontSize: '18px',
                width: '80%',
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
              }}
            >
              {joke.question}
            </Typography>
            {userData &&
            userData?.[0] &&
            userData?.[0].favorite_jokes &&
            userData?.[0].favorite_jokes.length > 0 &&
            userData?.[0].favorite_jokes.includes(joke.id) ? (
              <Box sx={{ minWidth: '50px' }}>
                <IconButton onClick={() => reduceFavoriteJoke(joke)} sx={{ color: 'red' }}>
                  <FavoriteTwoTone />
                </IconButton>
                <span sx={{ color: (theme) => theme.bodyColor }}>{joke.favorites.length}</span>
              </Box>
            ) : (
              <Box sx={{ minWidth: '50px' }}>
                <IconButton
                  onClick={() => newFavoriteJoke(joke)}
                  sx={{ color: (theme) => theme.bodyColor }}
                >
                  <FavoriteTwoTone />
                </IconButton>
                {joke.favorites && joke.favorites.length > 0 ? (
                  <span sx={{ color: (theme) => theme.bodyColor }}>{joke.favorites.length}</span>
                ) : (
                  <span sx={{ color: (theme) => theme.bodyColor }}>0</span>
                )}
              </Box>
            )}
          </Box>
          {expandedAnswersIndexes.includes(index) ? (
            <StyledButton onClick={() => toggleAnswerVisibility(index)}>
              Cacher
              <KeyboardArrowUp />
            </StyledButton>
          ) : (
            <StyledButton onClick={() => toggleAnswerVisibility(index)}>
              Réponse
              <KeyboardArrowDown />
            </StyledButton>
          )}

          <Box
            sx={{
              overflow: 'hidden',
              maxHeight: expandedAnswersIndexes.includes(index) ? '1000px' : 0,
              transition: 'max-height 0s ease-in, transform 0.3s ease',
              transform: expandedAnswersIndexes.includes(index) ? 'scaleY(1)' : 'scaleY(0)',
              transformOrigin: 'top',
            }}
          >
            <Typography
              component='p'
              sx={{ fontWeight: 500, wordWrap: 'break-word', whiteSpace: 'pre-line' }}
            >
              {joke.answer}
            </Typography>
          </Box>
        </StyledArticle>
      ))}
    </>
  );
};

export default Jokes;
