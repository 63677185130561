import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBackgroundProfile = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  '@media (max-width: 1200px)': {
    flexDirection: 'column',
  },
}));


export const StyledBoxProfile = styled(Box)(({ theme }) => ({
    width: '50%',
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  }));