import { styled } from '@mui/system';

export const StyledArticle = styled('article')(({ theme }) => ({
  marginBottom: '10px',
  padding: '16px',
  backgroundColor: theme.palette.backgroundBlock.default,
  boxShadow:
    '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  borderRadius: '16px',
  width: '100%',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    textAlign: 'left',
    alignItems: 'center',
  },
}));

export const StyledButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid ' + theme.palette.borderInput.default,
  borderColor: theme.palette.backgroundInput.default,
  background: theme.palette.backgroundInput.secondBackgroundInput,
  fontWeight: 550,
  border: 'none',
  paddingLeft: '7px',
  paddingRight: '3px',
  borderRadius: '4px',
  marginTop: '4px',
  marginBottom: '2px',
  color: theme.palette.text.default,
  '&:hover': {
    opacity: 0.8,
  },
}));
