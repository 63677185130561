import { db } from '../../firebase-config';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { toast } from 'react-toastify';

export async function useFirestoreUpdateCurrentUser(uid, data) {
  if (!uid || !data) return;

  const collectionRef = collection(db, 'users');
  const q = query(collectionRef, where('uid', '==', uid));

  getDocs(q)
    .then(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const docRef = doc(db, 'users', querySnapshot.docs[0].id);

        await updateDoc(docRef, {
          pseudo: data.pseudo,
          bio: data.bio,
        });
        toast.success('Profil mis à jour ✅', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })
    .catch(() => {
      toast.error('Erreur pendant la mise à jour du profil 🤨, veuillez réessayer svp.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
}
