import * as React from 'react';
import { Button } from '@mui/material';
import { useAuth } from '../../../context/auth/AuthProvider';
import { useGlobal } from '../../../context/global/GlobalProvider';
import Icon from '../../icons/icon';

const GoogleLogin = () => {
  const { setLoading } = useGlobal();
  const { signWithGoogle } = useAuth();

  const googleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    await signWithGoogle()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      onClick={googleSignIn}
      variant='contained'
      fullWidth
      sx={{
        justifyContent: 'left',
        padding: '6px 8px',
      }}
    >
      <Icon name='Google' />
      &nbsp;&nbsp;Google
    </Button>
  );
};

export default GoogleLogin;
