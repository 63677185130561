import { Box } from "@mui/system";
import { styled } from "styled-components";

export const StyledBgCreateJoke = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  width: '60%',

  '@media (max-width: 1200px)': {
    width: '100%',
  },
}));
