import { createTheme } from '@mui/material';

const LOCAL_STORAGE_KEY = 'isDarkTheme';
const ULTRA_LIGHT_GRAY = '#6E7781';
const LIGHT_GRAY = '#E6E5E7';
const DARK_GRAY = '#212121';
const RED = '#D82F27';
const LIGHT_INPUT_BORDER = '#B7BDC3';
const DARK_INPUT_BORDER = '#212121';
const BACKGROUND_INPUT = '#E7EBEE';

const baseTheme = createTheme({
  red: RED,
  ultraLightGray: ULTRA_LIGHT_GRAY,
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
});

const LIGHT_THEME = createTheme({
  ...baseTheme,
  bodyBackground:
    'linear-gradient(to right top, #38438b, #944b94, #d75a88, #ff7e71, #ffb25f, #ffeb68)',
  bodyColor: DARK_GRAY,
  borderColor: DARK_GRAY,
  ultraLightGray: ULTRA_LIGHT_GRAY,
  lightGray: LIGHT_GRAY,
  darkGray: DARK_GRAY,
  palette: {
    type: 'light',
    primary: {
      main: LIGHT_GRAY,
    },
    secondary: {
      main: DARK_GRAY,
    },
    background: {
      default: LIGHT_GRAY,
      color: DARK_GRAY,
    },
    text: {
      primary: DARK_GRAY,
    },
    label: {
      default: '#404040',
    },
    backgroundHeader: {
      default: '#E7EBEE',
    },
    backgroundBlock: {
      default: '#E7EBEE',
    },
    backgroundInput: {
      default: BACKGROUND_INPUT,
      secondBackgroundInput: '#CED2D4',
      selected: '#E9B1C2',
    },
    borderInput: {
      default: LIGHT_INPUT_BORDER,
    },
    hover: {
      backgroundColor: LIGHT_GRAY,
      color: DARK_GRAY,
    },
    divider: {
      default: LIGHT_INPUT_BORDER,
    },
    black: {
      default: DARK_GRAY,
    },
    buttonSelected: {
      default: 'rgba(33, 33, 33, 0.08)',
    },
  },
});

const DARK_THEME = createTheme({
  ...baseTheme,
  bodyBackground:
    'linear-gradient(to right, #356380, #356380, #356380, #34627f, #34627f, #34627f, #33627f, #33627f, #33627f, #336380, #326380, #326381)',
  bodyColor: LIGHT_GRAY,
  borderColor: DARK_GRAY,
  ultraLightGray: ULTRA_LIGHT_GRAY,
  lightGray: LIGHT_GRAY,
  darkGray: DARK_GRAY,
  palette: {
    type: 'dark',
    primary: {
      main: DARK_GRAY,
    },
    secondary: {
      main: LIGHT_GRAY,
    },
    background: {
      default: DARK_GRAY,
      color: LIGHT_GRAY,
    },
    text: {
      primary: LIGHT_GRAY,
    },
    label: {
      default: '#B8BABB',
    },
    backgroundHeader: {
      default:
        'linear-gradient(to right, #060211, #060211, #060212, #060112, #060112, #060112, #060112, #060112, #060112, #060011, #050011, #050010)',
    },
    backgroundBlock: {
      default: '#0A041A',
    },
    backgroundInput: {
      default: BACKGROUND_INPUT,
      secondBackgroundInput: '#85A4B6',
      selected: ULTRA_LIGHT_GRAY,
    },
    borderInput: {
      default: DARK_INPUT_BORDER,
    },
    hover: {
      backgroundColor: LIGHT_GRAY,
      color: DARK_GRAY,
    },
    divider: {
      default: DARK_INPUT_BORDER,
    },
    black: {
      default: DARK_GRAY,
    },
    buttonSelected: {
      default: 'rgba(230, 229, 231, 0.08)',
    },
  },
});

export { DARK_THEME, LIGHT_THEME, LOCAL_STORAGE_KEY };
