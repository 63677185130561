import * as React from 'react';
import { useAuth } from '../../context/auth/AuthProvider';
import { storage } from '../../firebase-config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { Box, Button } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { toast } from 'react-toastify';

const CustomAvatar = () => {
  const { authUser } = useAuth();
  const [photo, setPhoto] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [photoURL, setPhotoURL] = React.useState(
    'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
  );

  function handleChange(e) {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
  }

  function handleClick() {
    upload(photo, authUser);
  }

  React.useEffect(() => {
    if (authUser.photoURL) {
      setPhotoURL(authUser.photoURL);
    }
  }, [authUser]);

  // upload to storage
  async function upload(file, currentUser) {
    const fileRef = ref(storage, `avatars/${currentUser.uid}/avatar.png`);
    setLoading(true);

    await uploadBytes(fileRef, file);
    const photoURL = await getDownloadURL(fileRef);

    await updateProfile(currentUser, { photoURL })
      .then(() => {
        setLoading(false);
        toast.info('Photo de profil mise à jour', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error('Erreur lors de la mise à jour de la photo de profil', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '200px',
        '@media screen and (max-width: 640px)': {
          width: '170px',
        },
      }}
    >
      <input type='file' onChange={handleChange} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}></Box>
      <img
        sx={{ width: '100%', borderRadius: '10px' }}
        src={photoURL}
        alt='Avatar'
        className='avatar'
      />
      <Button
        component='label'
        variant='contained'
        sx={{ marginTop: '8px', fontSize: '12px' }}
        disabled={loading || !photo}
        onClick={handleClick}
        startIcon={<CloudUpload />}
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default CustomAvatar;
