import React from 'react';
import CreateJoke from '../../components/createJoke';
import { StyledBgCreateJoke } from './styles';
import { StyledTitle } from '../../components/title/styles';

const CreateJokePage = () => {
  return (
    <StyledBgCreateJoke>
      <StyledTitle>Créer une blague</StyledTitle>
      <CreateJoke />
    </StyledBgCreateJoke>
  );
};

export default CreateJokePage;
