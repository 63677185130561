import * as React from 'react';
import {
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth, db, provider } from '../../firebase-config';
import { query, addDoc, collection, getDocs, where } from 'firebase/firestore';
import { useFirestore } from '../../utils/get/useFirestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth() doit être utilisé avec AuthContextProvider');
  }
  return context;
};

export function AuthContextProvider(props) {
  const [authUser, setAuthUser] = React.useState();
  const [loadingData, setLoadingData] = React.useState(true);

  const navigate = useNavigate();

  const users = useFirestore('users'); // fetch all users
  const userData = users?.filter((user) => user?.uid === authUser?.uid); // filter currentUser

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setAuthUser(currentUser);
      setLoadingData(false);
    });
    return unsubscribe;
  }, []);

  const signWithGoogle = async () => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      const userQuery = query(collection(db, 'users'), where('uid', '==', user.uid));
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.empty) {
        await addDoc(collection(db, 'users'), {
          uid: user.uid,
          email: user.email,
          pseudo: null,
          date_creation: new Date(),
          bio: 'default description',
          jokes: [],
          favorite_jokes: [],
        });
        toast.success('Compte créé avec succès, bienvenue sur JokeHub !', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast('Bon retour sur JokeHub ! 🥳', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      navigate('/home'); // redirect to the home page
    } catch (error) {
      toast.error('Erreur lors de la connexion avec Google.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      throw error;
    }
  };

  const signIn = async (email, pwd) => {
    await signInWithEmailAndPassword(auth, email, pwd)
      .then(() => {
        toast(`Bon retour sur JokeHub ! 🥳`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch(() => {
        toast.error(
          'Erreur de connexion : les informations fournies sont probablement erronées. Veuillez réessayer.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          },
        );
      });
  };

  const signUp = (email, pwd, pseudo) => {
    const pseudoExist = users?.filter((user) => user?.pseudo === pseudo);
    if (pseudoExist.length !== 0) {
      toast.error('Ce pseudo est déjà utilisé.');
      return null;
    }
    createUserWithEmailAndPassword(auth, email, pwd)
      .then(({ user }) => {
        toast.success(`Compte créé avec succès. Bienvenue sur JokeHub !`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return addDoc(collection(db, 'users'), {
          uid: user.uid,
          email: email,
          pseudo: pseudo,
          date_creation: new Date(),
          bio: 'default description',
          jokes: [],
          favorite_jokes: [],
        });
      })
      .then(() => {
        navigate('/home'); // redirect to the home page
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          toast.error("L'addresse e-mail renseignée n'est pas valide.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if (error.code === 'auth/weak-password') {
          toast.error("Le mot de passe n'est pas assez sécurisé.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if (error.code === 'auth/email-already-in-use') {
          toast.error('Un compte est déjà associé à cet email.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  const signUserOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      toast.error('Erreur lors de la déconnexion');
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signWithGoogle,
        signUserOut,
        signUp,
        signIn,
        authUser,
        userData,
      }}
    >
      {!loadingData && props.children}
    </AuthContext.Provider>
  );
}
