import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useGlobal } from '../../context/global/GlobalProvider';

const Loader = () => {
  const { loading } = useGlobal();

  return (
    <Backdrop
      sx={{
        background: (theme) => theme.bodyBackground,
        color: (theme) => theme.bodyColor,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={loading}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default Loader;
