import React from 'react';

const IconLogoLight = () => (
  <svg
    width="47"
    height="73"
    viewBox="0 0 47 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>LogoLight</title>
    <mask
      id="mask0_13_11"
      maskUnits="userSpaceOnUse"
      x="9"
      y="0"
      width="38"
      height="73"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.643 0.202179C37.2839 0.202179 44.8561 7.95724 44.9723 17.5706L25.3591 17.57C25.4493 17.6045 29.5914 19.167 34.0443 21.6252C37.872 23.7383 42.1014 26.6578 44.3524 30.5153C45.773 32.9499 46.4534 35.9043 45.784 39.0307C44.8919 43.1958 42.392 47.3067 39.2337 51.8091C31.9717 62.1615 23.2422 72.493 23.2422 72.493L23.5341 63.2451L12.8465 63.1239C12.8465 63.1239 11.1302 58.4997 10.4 52.2558C9.79617 47.0927 9.49267 40.7848 9.47329 36.7274C9.44347 30.4658 10.1482 17.57 10.1482 17.57C10.2692 7.95693 18.0022 0.202179 27.643 0.202179Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13_11)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3862 0.199997C17.7453 0.199997 9.91618 7.95724 9.8 17.5706H44.9724C44.8562 7.95724 37.0271 0.199997 27.3862 0.199997Z"
        fill="#FECD07"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5339 73.0779C23.5339 73.0779 31.8942 62.149 39.1561 51.7965C42.3145 47.2939 44.8365 43.2464 45.7284 39.0813C46.3983 35.9549 45.7887 32.9899 44.368 30.5553C42.1171 26.6979 37.6942 23.6561 33.8665 21.543C29.3647 19.0577 25.3177 17.5701 25.3177 17.5701H9.79528C9.79528 17.5701 9.09125 30.4192 9.12107 36.6807C9.14044 40.7381 9.42016 47.3353 10.024 52.4989C10.7542 58.7422 12.4962 63.2452 12.4962 63.2452H23.5339V73.0779Z"
        fill="#2FAADD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7832 9.0032C33.7832 4.32351 29.7803 0.540094 25.108 0.506531H6.40771V17.5654L5.07027 17.57C5.07027 17.57 4.36625 30.4192 4.39607 36.6807C4.41544 40.7381 4.69516 47.3353 5.29902 52.4989C6.02919 58.7422 7.77124 63.2451 7.77124 63.2451H18.8089L21.6861 72.912C21.6861 72.912 28.4735 63.2221 35.5803 52.6333C39.3088 47.078 40.7295 44.214 41.6215 40.0489C42.291 36.9225 41.3725 32.439 39.9518 30.0045C38.8366 28.0934 36.3864 25.4152 33.0189 22.7093C29.9185 20.218 26.0002 17.9738 25.2767 17.566C29.8748 17.4521 33.7832 13.6316 33.7832 9.0032Z"
        fill="#EE3E36"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1901 15.1447C28.5245 15.1447 31.2276 12.4416 31.2276 9.10716C31.2276 5.77272 28.5245 3.06966 25.1901 3.06966C21.8557 3.06966 19.1526 5.77272 19.1526 9.10716C19.1526 12.4416 21.8557 15.1447 25.1901 15.1447Z"
        fill="white"
      />
      <path
        d="M25.1504 11.449C26.4552 11.449 27.5129 10.3913 27.5129 9.08647C27.5129 7.78174 26.4552 6.72397 25.1504 6.72397C23.8456 6.72397 22.7879 7.78174 22.7879 9.08647C22.7879 10.3913 23.8456 11.449 25.1504 11.449Z"
        fill="#2FAADD"
        stroke="#5A5DAA"
        strokeWidth="2.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9652 52.1151C9.31128 52.4979 10.3263 52.3257 10.3263 52.3257C10.3263 52.3257 24.7863 51.3807 32.4805 44.5979C35.5197 41.9188 38.2235 38.1805 37.4718 33.4549C37.1219 31.8155 36.6121 30.4021 35.8533 29.2154C34.1623 26.5707 31.5575 25.0422 29.0164 24.5786C22.7019 23.4265 15.1791 28.5838 11.4627 35.5521C10.139 38.0342 8.9652 40.4113 8.9652 43.2817C8.9652 50.9601 8.63093 51.7455 8.9652 52.1151Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.424866 51.2678C0.424866 51.2678 29.5983 51.5397 34.9953 37.3209C37.0224 31.9804 34.7167 28.4819 32.8569 26.5245C29.7653 23.2706 25.2242 23.7413 25.2242 23.7413C25.2242 23.7413 21.0346 23.893 16.9426 27.4665C14.9813 29.1794 13.1194 31.7943 11.589 34.4262C10.2563 36.7183 9.19641 39.0117 8.268 40.8803C4.0702 49.3284 0.424866 51.2678 0.424866 51.2678Z"
      fill="url(#paint0_linear_13_11)"
    />
    <mask
      id="mask1_13_11"
      maskUnits="userSpaceOnUse"
      x="0"
      y="23"
      width="36"
      height="29"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.424866 51.2678C0.424866 51.2678 29.5983 51.5397 34.9953 37.3209C37.0224 31.9804 34.7167 28.4819 32.8569 26.5245C29.7653 23.2706 25.2242 23.7413 25.2242 23.7413C25.2242 23.7413 21.0346 23.893 16.9426 27.4665C14.9813 29.1794 13.1194 31.7943 11.589 34.4262C10.2563 36.7183 9.19641 39.0117 8.268 40.8803C4.0702 49.3284 0.424866 51.2678 0.424866 51.2678Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_13_11)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40098 38.6399C8.40098 38.6399 15.2053 35.9386 12.3922 41.3837C11.4884 43.0431 13.1553 42.0306 14.842 41.1602C16.5288 40.2898 17.4702 40.2962 17.4702 40.2962C17.4702 40.2962 19.3315 40.1079 18.5631 41.5415C17.7949 42.9752 13.617 46.4287 13.617 46.4287C13.617 46.4287 12.0468 47.5728 14.7585 47.1359C17.4702 46.699 14.1211 49.6088 13.1823 50.2157C12.2435 50.8226 6.52914 52.0227 6.52914 52.0227L0.0404601 52.0878L-1.23702 50.0934L8.40098 38.6399Z"
        fill="#D6DF20"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_13_11"
        x1="20.6884"
        y1="48.9069"
        x2="33.6083"
        y2="33.6793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#69BD44" />
        <stop offset="1" stopColor="#3AB549" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconLogoLight;
