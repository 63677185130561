import * as React from 'react';
import Jokes from '../../joke';
import { Box, TablePagination } from '@mui/material';
import { useFirestore } from '../../../utils/get/useFirestore';

const AllJokes = () => {
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [selectedPage, setSelectedPage] = React.useState(0);

  const jokes = useFirestore('jokes') || [{}]; // Default to an empty array if jokes is undefined

  const endOffset = itemOffset + itemsPerPage;
  const currentJokes = jokes.slice(itemOffset, endOffset);
  const scrollRef = React.useRef(null);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  };

  const handleChangePage = (event, newPage) => {
    setSelectedPage(newPage);
    const newOffset = (newPage * itemsPerPage) % jokes.length;
    setItemOffset(newOffset);
    setTimeout(function () {
      scrollToBottom();
    }, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    const newOffset = (selectedPage * itemsPerPage) % jokes.length;
    setItemOffset(newOffset);
    setTimeout(function () {
      scrollToBottom();
    }, 0);
  };

  return (
    <>
      <Jokes currentJokes={currentJokes} />
      <Box ref={scrollRef} hidden />
      <TablePagination
        sx={{
          background: (theme) => theme.palette.backgroundBlock.default,
          color: 'black',
          margin: '15px 0px 25px 0px',
          borderRadius: '16px',
          fontWeight: 'bold',
        }}
        component='div'
        count={jokes.length}
        page={selectedPage}
        onPageChange={handleChangePage}
        rowsPerPage={itemsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default AllJokes;
