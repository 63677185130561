import { Box, IconButton, InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

export const StyledBackground = styled(Box)(() => ({
  padding: '12px 0px',
  margin: '0 auto',
  width: '100%',
}));

export const StyledSearch = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.1),
  border: '1px solid ' + theme.palette.borderInput.default,
  borderRadius: '14px',
  position: 'relative',
  margin: '0px 8px',
  transition: 'width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
  width: '40%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
  },
  '@media (max-width: 769px)': {
    width: '100%',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: '0.01px',
  borderRadius: '12px',
  backgroundColor: alpha(theme.palette.common.white, 0.1),
  border: '1px solid ' + theme.palette.borderInput.default,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
  },
}));
