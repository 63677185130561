import { collection, addDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';
import { toast } from 'react-toastify';
import { getDocUserByUid } from './user';

export const getAllJokes = () => {
  return new Promise(async (resolve, reject) => {
    const q = query(collection(db, 'jokes'));
    getDocs(q)
      .then((querySnapshot) => {
        resolve(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDocJokeById = async (id) => {
  return new Promise(async (resolve, reject) => {
    const q = query(collection(db, 'users'), where('id', '==', id));

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log('joke: ', doc.id, ' => ', doc.data());
          resolve(doc);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// getUserByUid and updatedJokesDocId List
export async function postJoke(userData, jokeData) {
  getDocUserByUid(userData[0].uid).then((docUser) => {
    if (docUser) {
      const docUserRef = doc(db, 'users', docUser.id);
      if (docUser) {
        jokeData.favorites = []; // list of user uid
        jokeData.author_id = userData[0].uid; // list of user uid
        jokeData.creation_date = new Date();
        addDoc(collection(db, 'jokes'), jokeData)
          .then((docJokeRef) => {
            userData[0].jokes.push(docJokeRef.id);
            updateDoc(docUserRef, { jokes: userData[0].jokes }).then(() => {
              toast.success('Joke ajouté ✅', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
          })
          .catch(() => {
            toast.error('Erreur', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
      }
    }
  });
}

export async function addFavoriteJoke(userData, jokeData) {
  getDocUserByUid(userData?.[0].uid).then((docUser) => {
    if (docUser) {
      const docUserRef = doc(db, 'users', docUser.id);
      const docJokeRef = doc(db, 'jokes', jokeData.id);

      if (docUserRef && docJokeRef) {
        if (
          !userData?.[0].favorite_jokes.includes(docJokeRef.id) &&
          !jokeData.favorites.includes(userData?.[0].uid)
        ) {
          jokeData.favorites.push(userData?.[0].uid); // list of user uid

          updateDoc(docJokeRef, { favorites: jokeData.favorites })
            .then(() => {
              userData[0].favorite_jokes.push(docJokeRef.id);
              updateDoc(docUserRef, { favorite_jokes: userData[0].favorite_jokes })
                .then(() => {
                  toast.success('Like', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                })
                .catch(() => {
                  toast.error('Erreur update user', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                });
            })
            .catch(() => {
              toast.error('Erreur update joke', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            });
        } else {
          toast.warning('Cette blague est déjà dans vos favoris', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        toast.warning('User or Joke not found', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  });
}

export async function removeFavoriteJoke(userData, jokeData) {
  getDocUserByUid(userData?.[0].uid).then((docUser) => {
    if (docUser) {
      const docUserRef = doc(db, 'users', docUser.id);
      const docJokeRef = doc(db, 'jokes', jokeData.id);

      if (docUserRef && docJokeRef) {
        const filteredFavorites = jokeData.favorites.filter(
          (userUid) => userUid !== userData[0].uid,
        );
        updateDoc(docJokeRef, { favorites: filteredFavorites })
          .then(() => {
            const filteredUserFav = userData[0].favorite_jokes.filter(
              (jokeId) => jokeId !== docJokeRef.id,
            );
            updateDoc(docUserRef, { favorite_jokes: filteredUserFav })
              .then(() => {
                toast.success('Unlike', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              })
              .catch(() => {
                toast.error('Erreur update user', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
          })
          .catch(() => {
            toast.error('Erreur update joke', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          });
      } else {
        toast.warning('User or Joke not found', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  });
}
