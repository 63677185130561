import React from 'react';
import UpdateProfile from '../../components/updateProfile/index';
import { StyledBackgroundProfile, StyledBoxProfile } from './styles';
import { StyledTitle } from '../../components/title/styles';

const ProfilePage = () => {
  return (
    <StyledBackgroundProfile>
      <StyledBoxProfile>
        <StyledTitle>Mes informations</StyledTitle>
        <UpdateProfile />
      </StyledBoxProfile>
    </StyledBackgroundProfile>
  );
};

export default ProfilePage;
