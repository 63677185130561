import * as React from 'react';
import { useGlobal } from '../../context/global/GlobalProvider';
import { useAuth } from '../../context/auth/AuthProvider';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Send } from '@mui/icons-material';

const LoginWithEmailAndPassword = () => {
  const { setLoading } = useGlobal();
  const { signIn } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await signIn(data.email, data.password);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant='outlined'
        label='Email'
        fullWidth
        autoComplete='email'
        autoFocus
        {...register('email', {
          required: 'Champ obligatoire',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Adresse email invalide',
          },
        })}
        error={!!errors?.email}
        helperText={errors?.email ? errors.email.message : null}
        sx={{
          marginTop: 1,
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
          },
          '& label': {
            color: (theme) => theme.palette.text.primary,
          },
          '& label.Mui-focused': {
            color: (theme) => theme.palette.text.primary,
          },
        }}
      />
      <TextField
        variant='outlined'
        label='Mot de passe'
        type='password'
        fullWidth
        autoComplete='password'
        autoFocus
        {...register('password', {
          required: 'Champ obligatoire',
        })}
        error={!!errors?.password}
        helperText={errors?.password ? errors.password.message : null}
        sx={{
          marginTop: 1.5,
          color: (theme) => theme.palette.secondary.main,
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
          },
          '& label': {
            color: (theme) => theme.palette.text.primary,
          },
          '& label.Mui-focused': {
            color: (theme) => theme.palette.text.primary,
          },
        }}
      />
      <Button
        sx={{
          marginBottom: 1.5,
          marginTop: 1.5,
        }}
        type='submit'
        variant='contained'
        fullWidth
        endIcon={<Send />}
      >
        Se connecter
      </Button>
    </form>
  );
};

export default LoginWithEmailAndPassword;
