import React from 'react';
import AllJokes from '../../components/listJokes/allJokes';
import { StyledBgAllJokes } from './styles';

const AllJokesPage = () => {
  return (
    <StyledBgAllJokes>
      <AllJokes />
    </StyledBgAllJokes>
  );
};

export default AllJokesPage;
