import * as React from 'react';
import { Button } from '@mui/material';
import { CreateSharp } from '@mui/icons-material';



const ButtonGenerate = (props) => {

  return (
    <Button
      onClick={props.onClick}
      sx={{
        background: (theme) => theme.palette.background.default,
        fontWeight: 'bold',
        marginTop: props.marginTop,
      }}
      type='submit'
      variant='contained'
      startIcon={<CreateSharp />}
    >
      {props.value}
    </Button>
  );
};

export default ButtonGenerate;
