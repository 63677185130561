import React from "react";
import PropTypes from "prop-types";
import IconDiscord from "./discord";
import IconGitHub from "./github";
import IconLinkedin from "./linkedin";
import IconBtnScrollTop from "./btnScrollTop";
import IconLightTheme from "./lightTheme";
import IconDarkTheme from "./darkTheme";
import IconGoogle from "./google";

const Icon = ({ name }) => {
  switch (name) {
    case "Discord":
      return <IconDiscord />;
    case "GitHub":
      return <IconGitHub />;
    case "Google":
      return <IconGoogle />;
    case "Linkedin":
      return <IconLinkedin />;
    case "BtnScrollTop":
      return <IconBtnScrollTop />;
    case "LightTheme":
      return <IconLightTheme />;
    case "DarkTheme":
      return <IconDarkTheme />;
    case "LogoLight":
      return <IconLightTheme />;
    default:
      return;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
