import * as React from 'react';
import ThemeContext from '../../context/themes/ThemeContext';
import IconLightTheme from '../icons/lightTheme';
import IconDarkTheme from '../icons/darkTheme';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const ButtonSwitchTheme = () => {
  const { isDarkTheme, toggleTheme } = React.useContext(ThemeContext);

  const handleThemeChange = (newTheme) => {
    if (newTheme !== null) {
      toggleTheme();
    }
  };

  return (
    <ToggleButtonGroup value={isDarkTheme} exclusive onChange={handleThemeChange}>
      <ToggleButton value={true} size='large' sx={{ color: (theme) => theme.palette.text.primary }}>
        <IconLightTheme />
      </ToggleButton>
      <ToggleButton
        value={false}
        size='large'
        sx={{ color: (theme) => theme.palette.text.primary }}
      >
        <IconDarkTheme />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ButtonSwitchTheme;
