import { styled } from '@mui/system';

export const StyledTitle = styled('h1')(({theme}) => ({
  fontSize: '28px',
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: '1.5em',
  paddingBottom: '15px',
  padding: 0,
  margin: 0,
  marginBottom: '12px',
  color: theme.bodyColor,
  transition: 'all 0.4s ease 0s',
  position: 'relative',

  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: '-4px',
    height: '5px',
    width: '55px',
    backgroundColor: theme.bodyColor
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: '-2px',
    height: '1px',
    width: '95%',
    maxWidth: '255px',
    backgroundColor: theme.bodyColor
  },
}));

export const StyledBgTitle = styled('div')(() => ({
  background: '#f8f8f8',
  width: '90%',
  maxWidth: '500px',
  border: '3px solid rgba(0, 0, 0, 0.08)',
  position: 'relative',
  padding: 0,
  margin: 0,
  fontFamily: 'Raleway, sans-serif',
  fontWeight: 300,
  fontSize: '40px',
  color: '#080808',
  transition: 'all 0.4s ease 0s',
}));
