import * as React from 'react';
import { Box, TablePagination } from '@mui/material';
import { useAuth } from '../../../context/auth/AuthProvider';
import Jokes from '../../joke';
import { getJokesByUser } from '../../../services/user';

const UserJokes = () => {
  const { userData } = useAuth();

  const scrollRef = React.useRef(null);
  const [selectedPage, setSelectedPage] = React.useState(0);
  const [jokesPerPage, setJokesPerPage] = React.useState(10);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [endOffset, setEndOffset] = React.useState(0);

  const [userJokes, setUserJokes] = React.useState([]);
  const [currentJokes, setCurrentJokes] = React.useState([]);

  React.useEffect(() => {
    if (userJokes && userJokes.length === 0) {
      var isApiSubscribed = true;
      getJokesByUser(userData).then((data) => {
        if (isApiSubscribed) setUserJokes(data);
      });
    }
    return () => {
      isApiSubscribed = false;
    };
  }, [userData, userJokes]);

  React.useEffect(() => {
    if (userJokes && userJokes.length > 0) {
      setEndOffset(itemOffset + jokesPerPage);
      setCurrentJokes(userJokes.slice(itemOffset, endOffset));
    }
  }, [endOffset, itemOffset, jokesPerPage, userJokes]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  };

  const handleChangePage = (newPage) => {
    if (userJokes.length > 0) {
      setSelectedPage(newPage);
      const newOffset = (newPage * jokesPerPage) % userJokes.length;
      setItemOffset(newOffset);
      setTimeout(function () {
        scrollToBottom();
      }, 0);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (userJokes.length > 0) {
      setJokesPerPage(parseInt(event.target.value, 10));
      const newOffset = (selectedPage * jokesPerPage) % userJokes.length;
      setItemOffset(newOffset);
      setTimeout(function () {
        scrollToBottom();
      }, 0);
    }
  };

  return (
    <>
      {currentJokes && currentJokes.length > 0 ? (
        <Jokes currentJokes={currentJokes} />
      ) : (
        <div>Ajouter votre première blague!</div>
      )}
      <Box ref={scrollRef} hidden />
      <TablePagination
        sx={{
          background: (theme) => theme.palette.backgroundBlock.default,
          color: 'black',
          margin: '15px 0px 25px 0px',
          borderRadius: '16px',
          fontWeight: 'bold',
        }}
        component='div'
        count={userJokes && userJokes.length ? userJokes.length : 0}
        page={selectedPage}
        onPageChange={handleChangePage}
        rowsPerPage={jokesPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default UserJokes;
