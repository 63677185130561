import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBgUserJokes = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
}));

export const StyledBoxUserJokes = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '12px'
}));
