import React from 'react';
import { StyledTitle } from '../../components/title/styles';
import UserJokes from '../../components/listJokes/userJokes';
import { StyledBgUserJokes, StyledBoxUserJokes } from './styles';

const UserJokesPage = () => {
  return (
    <StyledBgUserJokes>
      <StyledTitle>Mes blagues</StyledTitle>
      <StyledBoxUserJokes>
        <UserJokes />
      </StyledBoxUserJokes>
    </StyledBgUserJokes>
  );
};

export default UserJokesPage;
