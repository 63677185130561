import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/auth/AuthProvider';
import { useGlobal } from '../../context/global/GlobalProvider';
import { Box, TextField } from '@mui/material';
import CustomAvatar from '../avatar';
import { useFirestoreUpdateCurrentUser } from '../../utils/put/useFirestoreUpdateCurrentUser';
import ButtonSave from '../button/buttonSave/buttonSave';
import { StyledLabel } from '../label/styles';

const UpdateProfile = () => {
  const { setLoading } = useGlobal();
  const { userData } = useAuth();

  React.useEffect(() => {
    if (userData?.[0].pseudo) {
      document.getElementById('update-profile-field-pseudo').value = userData?.[0].pseudo;
      document.getElementById('update-profile-field-bio').value = userData?.[0].bio;
      document.getElementById('update-profile-field-email').value = userData?.[0].email;
    }
  }, [userData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.pseudo = document.getElementById('update-profile-field-pseudo').value;
    data.bio = document.getElementById('update-profile-field-bio').value;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useFirestoreUpdateCurrentUser(userData?.[0].uid, data);
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'inline-block',
          marginTop: '10px',
          padding: '8px 10px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '5px',
          borderColor: (theme) => theme.palette.borderInput.default,
          background: (theme) => theme.palette.backgroundInput.default,
        }}
      >
        <CustomAvatar />
      </Box>

      <StyledLabel><i>Email (Non modifiable)</i></StyledLabel>
      <TextField
        id='update-profile-field-email'
        variant='outlined'
        input='text'
        fullWidth
        disabled
        aria-readonly
        sx={{
          '& .MuiOutlinedInput-input': {
            fontWeight: '549',
            color: (theme) => theme.bodyColor,
            zIndex: 100
          },
          '& div .MuiOutlinedInput-notchedOutline': {
            backgroundColor: (theme) => theme.palette.backgroundInput.default,
          },
        }}
      />

      <StyledLabel>Pseudo *</StyledLabel>
      <TextField
        id='update-profile-field-pseudo'
        variant='outlined'
        input='text'
        fullWidth
        {...register('pseudo', {
          pattern: {
            value: /^([a-zA-Z0-9]){3,20}$/,
            message: 'Pseudo invalide',
          },
        })}
        error={!!errors?.pseudo}
        helperText={errors?.pseudo ? errors.pseudo.message : null}
        sx={{
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
            backgroundColor: (theme) => theme.palette.backgroundInput.default,
          },
          '& .MuiOutlinedInput-input': {
            color: (theme) => theme.palette.black.default,
            zIndex: 1,
          },
        }}
      />

      <StyledLabel>Bio</StyledLabel>
      <TextField
        id='update-profile-field-bio'
        input='text'
        fullWidth
        multiline
        rows='4'
        error={!!errors?.bio}
        helperText={errors?.bio ? errors.bio.message : null}
        {...register('bio')}
        sx={{
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
            backgroundColor: (theme) => theme.palette.backgroundInput.default,
          },
          '& .MuiOutlinedInput-input': {
            color: (theme) => theme.palette.black.default,
            zIndex: 1,
          },
        }}
      />

      <ButtonSave value='Enregistrer' />
    </form>
  );
};

export default UpdateProfile;
