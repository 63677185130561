import { useState, useEffect } from 'react';
import { onSnapshot, collection } from 'firebase/firestore';
import { db } from '../../firebase-config';

/*
 * @param ref references collection_name
 */
export function useFirestore(ref) {
  const [data, setData] = useState();
  const collectionRef = collection(db, ref);

  useEffect(() => {
    if (!ref) return;

    onSnapshot(collectionRef, (snapshot) => {
      setData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return data;
}
