import * as React from 'react';
import { ThemeProvider } from '@emotion/react';
import ThemeContext from './ThemeContext';
import { DARK_THEME, LIGHT_THEME, LOCAL_STORAGE_KEY } from './themes';
import { CssBaseline } from '@mui/material';

const ThemeProviderHandler = (props) => {
  const [isDarkTheme, setIsDarkTheme] = React.useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) === 'true',
  );

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    localStorage.setItem(LOCAL_STORAGE_KEY, !isDarkTheme);
  };

  const ctxThemeValue = {
    isDarkTheme: isDarkTheme,
    toggleTheme: toggleTheme,
  };

  const getTheme = React.useCallback(() => {
    return isDarkTheme ? DARK_THEME : LIGHT_THEME;
  }, [isDarkTheme]);

  React.useEffect(() => {
    // Update body styles when isDarkTheme change
    const updatedTheme = getTheme();
    document.body.style.background = updatedTheme.bodyBackground;
    document.body.style.color = updatedTheme.bodyColor;
  }, [getTheme]);

  return (
    <ThemeContext.Provider value={ctxThemeValue}>
      <CssBaseline />
      <ThemeProvider theme={getTheme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderHandler;
