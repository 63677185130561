import { Close } from '@mui/icons-material';
import { ListItemButton, ListItemText } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const drawerWidth = 270;

export const StyledDrawer = styled('div')(({ theme }) => ({
  top: '0',
  position: 'fixed',
  zIndex: 100,
  height: '100vh',
  background: theme.palette.backgroundHeader.default,
  color: theme.palette.bodyColor,
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 5px',
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px',
  borderBottomRightRadius: '2px',
  visibility: 'visible',
  transition: 'all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
  maxWidth: drawerWidth,
  width: '100%',
  '@media (max-width: 769px)': {
    visibility: 'hidden',
  },
}));

export const StyledListItemText = styled(ListItemText)(() => ({}));

export const StyledNav = styled('nav')(() => ({
  marginBottom: 'auto',
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.bodyColor,
  '&:hover': {
    backgroundColor: theme.palette.hover.backgroundColor,
    color: theme.palette.hover.color,
    '& .MuiListItemIcon-root': {
      color: theme.palette.hover.color,
    },
  },
}));

export const StyledCloseIcon = styled(Close)(({ theme }) => ({
  padding: '7px',
  margin: '12px 18px',
  width: '2.4rem',
  height: '2.4rem',
  fontWeight: 'bold',
  borderRadius: '22px',
  border: '1px solid ' + theme.palette.borderInput.default,
  backgroundColor: alpha(theme.palette.common.white, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
  },
}));
