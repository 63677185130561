import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import GitHubIcon from '@mui/icons-material/GitHub';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '../logout/index.jsx';
import { Box } from '@mui/material';
import {
  StyledBackground,
  StyledSearch,
  SearchIconWrapper,
  StyledInputBase,
  StyledIconButton,
} from './styles';

const SearchAppBar = () => {
  const handleAsideDrawer = () => {
    const asideDrawer = document.getElementById('aside-drawer');
    if (asideDrawer.style.visibility === 'visible') {
      document.getElementById('main').style.margin = '0 auto';
      asideDrawer.style.visibility = 'hidden';
      asideDrawer.style.width = '0%';
      asideDrawer.style.opacity = 0;
    } else {
      document.getElementById('main').style.marginLeft = '270px';
      asideDrawer.style.visibility = 'visible';
      asideDrawer.style.width = '100%';
      asideDrawer.style.opacity = 1;
    }
  };

  const onBlur = () => {
    if (window.innerWidth > 769) {
      document.getElementById('search-box').style.width = '40%';
    }
  };

  return (
    <StyledBackground>
      <AppBar
        position='static'
        sx={{ background: (theme) => theme.palette.backgroundHeader.default, borderRadius: '8px' }}
      >
        <Toolbar>
          <StyledIconButton size='small' edge='start' color='inherit' onClick={handleAsideDrawer}>
            <MenuIcon />
          </StyledIconButton>

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'right' }}>
            <StyledSearch id='search-box'>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder='Rechercher...'
                inputProps={{ 'aria-label': 'search' }}
                onFocus={() => (document.getElementById('search-box').style.width = '100%')}
                onBlur={onBlur}
              />
            </StyledSearch>

            <StyledIconButton
              size='small'
              edge='start'
              color='inherit'
              onClick={() => window.open('https://github.com/nicolaslefaou')}
            >
              <GitHubIcon />
            </StyledIconButton>
            <Logout />
          </Box>
        </Toolbar>
      </AppBar>
    </StyledBackground>
  );
};

export default SearchAppBar;
