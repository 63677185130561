import * as React from 'react';
import { getAuth } from 'firebase/auth';
import { ButtonBase, alpha } from '@mui/material';
import { LogoutOutlined } from '@mui/icons-material';
import { useAuth } from '../../context/auth/AuthProvider';

const Logout = () => {
  const auth = getAuth();
  const { signUserOut } = useAuth();

  function handleLogout() {
    signUserOut(auth).catch((error) => {
      console.error(error);
    });
  }

  return (
    <ButtonBase
      sx={{
        marginLeft: '8px',
        padding: '5px',
        borderRadius: '13px',
        border: (theme) => '1px solid ' + theme.palette.borderInput.default,
        backgroundColor: (theme) => alpha(theme.palette.common.white, 0.1),
        '&:hover': {
          backgroundColor: (theme) => alpha(theme.palette.common.white, 0.15),
        },
      }}
      onClick={handleLogout}
    >
      <LogoutOutlined />
    </ButtonBase>
  );
};

export default Logout;
