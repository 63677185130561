import React from 'react';
import { Box, Typography, Container, Divider, Link } from '@mui/material';
import GoogleLogin from '../../components/login/google';
import LoginWithEmailAndPassword from '../../components/login';
import ButtonSwitchTheme from '../../components/buttonSwitchTheme';

const LoginPage = () => {
  return (
    <Container
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.backgroundBlock.default,
          borderRadius: '16px',
          boxShadow:
            '0px 2px 8px 2px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 10px 10px 0px rgba(0,0,0,0.12)',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            borderBottom: (theme) => '1px solid ' + theme.palette.divider.default,
            fontWeight: 550,
            borderRadius: '12px 12px 0px 0px',
            textAlign: 'center',
            padding: '6px 0px',
          }}
        >
          JokeHub > Connexion
        </Typography>

        <Container
          sx={{
            display: 'flex',
            padding: '15px 20px',
            '@media screen and (max-width: 640px)': { flexDirection: 'column' },
          }}
        >
          <Box component='div'>
            <Box component='p' sx={{ fontWeight: 500, paddingBottom: '4px', }}>
              S'inscrire ou se connecter<br /> en quelques clics avec..
            </Box>
            <Box component='p'>
              <GoogleLogin />
            </Box>
          </Box>

          <Divider
            orientation='vertical'
            flexItem
            sx={{ color: (theme) => theme.palette.divider.default, margin: '0px 18px' }}
          ></Divider>

          <Box>
            <Box component='p' sx={{ fontWeight: 500 }}>
              J'ai déjà un compte
            </Box>
            <LoginWithEmailAndPassword />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link
                sx={{
                  textDecoration: 'underline',
                  color: (theme) => theme.palette.secondary.main,
                }}
                href='/signup'
              >
                S'inscrire
              </Link>
              <ButtonSwitchTheme sx={{ marginRight: 'auto' }} />
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default LoginPage;
