import * as React from 'react';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useGlobal } from '../../context/global/GlobalProvider';
import ButtonSave from '../button/buttonSave/buttonSave';
import { StyledLabel } from '../label/styles';
import { useAuth } from '../../context/auth/AuthProvider';
import { postJoke } from '../../services/jokes';
import ButtonGenerate from '../button/buttonGenerate/buttonGenerate';
import { generateChuckNorrisJoke } from '../../services/generateJoke/chuckNorris';

const CreateJoke = () => {
  const { setLoading } = useGlobal();
  const { userData } = useAuth();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (jokeData) => {
    try {
      setLoading(true);
      await postJoke(userData, jokeData).then(() => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ButtonGenerate marginTop='10px' value='Chuck Norris' onClick={generateChuckNorrisJoke} />
      <StyledLabel>Question *</StyledLabel>
      <TextField
        id='create-joke-question'
        input='text'
        fullWidth
        multiline
        rows='3'
        {...register('question', {
          required: 'Champ obligatoire',
        })}
        sx={{
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
            backgroundColor: (theme) => theme.palette.backgroundInput.default,
            color: (theme) => theme.palette.black.default,
          },
          '& .MuiOutlinedInput-input': {
            color: (theme) => theme.palette.black.default,
            zIndex: 3,
          },
        }}
      />

      <StyledLabel>Réponse *</StyledLabel>
      <TextField
        input='text'
        fullWidth
        multiline
        rows='3'
        {...register('answer', {
          required: 'Champ obligatoire',
        })}
        sx={{
          '& div .MuiOutlinedInput-notchedOutline': {
            borderColor: (theme) => theme.palette.borderInput.default,
            backgroundColor: (theme) => theme.palette.backgroundInput.default,
          },
          '& .MuiOutlinedInput-input': {
            color: (theme) => theme.palette.black.default,
            zIndex: 3,
          },
        }}
      />
      <ButtonSave value='Ajouter' />
    </form>
  );
};

export default CreateJoke;
